import React from "react";
import {Helmet} from "react-helmet";

import {TopSection} from "@templates/selfworkers";
import {HelpSection} from "@templates/selfworkers/HelpSection";

import {Layout} from "@components";
import {FindOrderSection} from "@templates/selfworkers/FindOrderSection";
import {UsePlatformSection} from "@templates/selfworkers/UsePlatformSection";
import {DownloadMarketSection} from "@templates/selfworkers/DownloadMarketSection";

const MarketplacePage = () => (
    <>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>Работа для самозанятых | Вакансии для самозанятых удаленные | Самозанятые сервисы подработки</title>
            <meta name="description"
                  content="Работа для самозанятых граждан. Поиск заказов для самозанятых. Место, где можно найти работу самозанятому. Быстро и безопасно!"/>
            <link rel="canonical" href="https://xn--80aapgyievp4gwb.xn--p1ai/selfworkers"/>
            <link rel="icon" type="image/svg+xml" href="/favicon.svg" sizes="any"/>
            <link rel="apple-touch-icon" href="/apple-touch-icon.png" type="image/png"/>
        </Helmet>
        <Layout>
            <TopSection/>
            <HelpSection/>
            <FindOrderSection/>
            <UsePlatformSection/>
            <DownloadMarketSection/>


        </Layout>
    </>
);

export default MarketplacePage;
